import Immutable from 'immutable';
import { DefaultDraftBlockRenderMap } from 'draft-js';

const codeBlock = Immutable.Map({
  'code-block': {
    element: 'pre',
  },
});

/*
 Mapping that returns containers for the various block types.
 Default draft rendermap is used without specific tags
 */
const _BlockRenderMap = DefaultDraftBlockRenderMap.filter(
  (item) => ['h1', 'h5', 'h6', 'section', 'article'].indexOf(item.element) < 0,
);

const BlockRenderMap = _BlockRenderMap.merge(codeBlock);

export default BlockRenderMap;
